import React from "react";
import logoSVG from "../images/hedgehog-trivia-logo-white.svg";

const WIDTH = 200;

const Logo = () => (
  <img width={WIDTH} src={logoSVG} alt="Hedgehog Trivia Logo" />
);

export default Logo;
