import React, { useContext, useState } from "react";
import styled from "styled-components";
import UserContext from "../contexts/UserContext";
import Button from "./buttons/Button";
import getLogger from "../util/getLogger";
import Bugsnag from "@bugsnag/js";
import {
  REGULAR_TEXT,
  STATUS_CRITICAL,
  STATUS_OK,
  STATUS_UNKNOWN,
  STATUS_WARNING,
} from "../colors";

const logger = getLogger("Announcement");

export const OK = "OK";
export const WARNING = "WARNING";
export const CRITICAL = "CRITICAL";
export const UNKNOWN = "UNKNOWN";

const AnnouncementWrapper = styled.div`
  z-index: 1;
  background-color: ${STATUS_OK};
  color: ${REGULAR_TEXT};
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ level }) =>
    level === WARNING &&
    `
    color: ${REGULAR_TEXT};
    background-color: ${STATUS_WARNING};
  `}
  ${({ level }) =>
    level === CRITICAL &&
    `
    color: ${REGULAR_TEXT};
    background-color: ${STATUS_CRITICAL};
  `}
  ${({ level }) =>
    level === UNKNOWN &&
    `
    color: ${REGULAR_TEXT};
    background-color: ${STATUS_UNKNOWN};
  `}
`;

export default () => {
  const { announcement } = useContext(UserContext);
  const reconnect = () => {
    logger.info("Clicked reconnect");
  };

  const [reconnecting, setReconnecting] = useState(false);
  const [reconnectingFailed, setReconnectingFailed] = useState(false);

  if (!announcement) {
    return null;
  }

  const handleReconnectClick = async () => {
    logger.debug("in handleReconnectClick");
    setReconnecting(true);
    try {
      await reconnect();
      setReconnectingFailed(false);
    } catch (e) {
      Bugsnag.notify(e);

      logger.info(`got an exception while attempting to reconnect: ${e}`);
      logger.debug(e);
      setReconnectingFailed(true);
    }
    setReconnecting(false);
  };

  return (
    <AnnouncementWrapper level={announcement.level}>
      {announcement.text}
      {announcement.type === "lostConnection" && (
        <span>
          {reconnectingFailed
            ? "Reconnecting failed."
            : "Lost audio/video connection."}{" "}
          <Button onClick={handleReconnectClick} disabled={reconnecting}>
            {reconnecting ? "Reconnecting" : "Reconnect"}
          </Button>
        </span>
      )}
    </AnnouncementWrapper>
  );
};
