import React from "react";
import styled from "styled-components";

const Box = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  flex: 1 1 0%;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default (props) => {
  return <Box {...props} />;
};
