import styled from "styled-components";
import A from "./A";
import { WHITE } from "../colors";

export default styled(A)`
  color: ${WHITE};

  :hover {
    color: ${WHITE};
  }
`;
