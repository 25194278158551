import styled from "styled-components";
import Button from "./Button";
import { LIGHT_GREY_1, NEUTRAL_2 } from "../../colors";

export default styled(Button)`
  background-color: ${NEUTRAL_2};
  border-color: ${NEUTRAL_2};
  color: ${LIGHT_GREY_1};

  :hover {
    box-shadow: 0 0 0 2px ${NEUTRAL_2};
  }

  ${({ disabled }) =>
    disabled &&
    `
    :hover {
      box-shadow: none;
    }
  `}
`;
